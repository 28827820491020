.container
	margin-x auto
	width 100%
	max-width 1440px

	+below($desktopLg)
		max-width 1080px

	+below($desktop)
		max-width 960px

	+below($tablet)
		max-width 768px

	+below($tabletSmall)
		padding-x 20px
		max-width 100%

	+below($phone)
		padding-x 16px
