.any-questions
	display flex
	justify-content space-between
	background-color $white
	opacity 0
	transform translateY(100px)
	$animated = transform, opacity
	animate $animated .5s

	+below($phone)
		flex-direction column

	&__form
		padding-x 100px
		width 100%
		max-width 690px
		background-color $dark-blue

		+below($desktopLg)
			padding-x 75px
			max-width 517px

		+below($desktop)
			padding-x 66px
			max-width 460px

		+below($tablet)
			padding-x 54px
			max-width 368px

		+below($tabletSmall)
			padding-x 46px
			max-width 352px

		+below($phone)
			padding-x 16px
			width 100%
			max-width 100%

	&__content
		padding 30px
		width 100%

		+below($desktopLg)
			padding-x 20px
			padding-y 26px

		+below($tablet)
			padding 16px

		+below($phone)
			padding 16px
			width 100%

	&__questions
		margin-bottom 32px
		font-family $font-nunito-b
		font-size $font-big
		line-height 34px
		color $black

		+below($desktopLg)
			margin-bottom 24px
			font-size $font-default
			line-height 25px

		+below($desktop)
			margin-bottom 24px
			font-size $font-middle
			line-height 20px

		+below($tablet)
			margin-bottom 12px
			font-size $font-little
			line-height 18px

		+below($tabletSmall)
			margin-bottom 16px
			font-size $font-low
			line-height 22px

	&__info
		display flex
		flex-direction column

		&:not(:last-child)
			margin-bottom 24px

			+below($desktopLg)
				margin-bottom 20px

			+below($tablet)
				margin-bottom 14px

	&__title
		margin-bottom 8px
		font-size $font-middle
		line-height 25px
		color $gray

		+below($desktopLg)
			font-size $font-little
			line-height 19px

		+below($desktop)
			font-size $font-small
			line-height 16px

		+below($tablet)
			margin-bottom 4px

		+below($tabletSmall)
			margin-bottom 8px

		+below($phone)
			margin-bottom 4px
			font-size $font-little
			line-height 19px

	&__contact
		font-family $font-nunito-b
		font-size $font-default
		line-height 30px
		text-decoration none
		color $black
		transition color .3s

		&:hover
			color $bright-blue

		&_phone
			margin-bottom 8px

			+below($desktopLg)
				margin-bottom 6px

		&_address
			&:hover
				color $black

		+below($desktopLg)
			font-size $font-middle
			line-height 25px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tablet)
			font-size $font-small
			line-height 16px

		+below($tabletSmall)
			font-size $font-little
			line-height 19px

		+below($phone)
			font-size $font-low
			line-height 22px

