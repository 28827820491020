.popup
	position fixed
	z-index -100000
	top 0
	right 0
	left 0
	bottom 0
	display flex
	justify-content center
	align-items center
	visibility hidden

	overflow-x auto
	overflow-y scroll
	font-size 0
	background-color rgba(black, .1)
	opacity 0

	transition z-index 0s .6s, visibility 0s .6s, opacity .6s
	will-change z-index, visibility, opacity

	&_state_active
		z-index 10000
		visibility visible
		opacity 1
		transition z-index 0s 0s, visibility 0s 0s, opacity .6s

	&__box
		position relative
		z-index 1
		display inline-block
		overflow hidden
		min-height 100px
		background-color $white
		border-radius 8px

		+below($phone)
			width 100%
			height 100%
			border-radius 0

	&__inner
		overflow auto
		height 100%
		max-height calc(100vh - 100px)
		+below($phone)
			max-height 100%

	&__close
		position absolute
		z-index 100
		top 0
		right 10px
		padding 0
		display flex
		justify-content center
		align-items center
		size 40px
		cursor pointer
		color $black

		background-color transparent
		border none

		&:hover
			color $red

		+below($phone)
			top 0
			right 0

	&__icon-close
		size 100%
		color currentColor
		animate color .3s
