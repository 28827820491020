.technique
	overflow hidden
	width 100%
	font-size 0
	background-color $white
	border-radius 6px
	transition box-shadow .3s

	&:hover
		box-shadow $small-card-shadow

	&__image
		width 100%
		height 160px
		object-fit cover
		object-position center

		+below($tabletSmall)
			height 96px

		+below($phone)
			height 180px

	&__content
		padding-y 20px
		display flex
		flex-direction column
		text-align center

		+below($desktopLg)
			padding-x 23px
			padding-y 16px

		+below($desktop)
			padding-y 14px

		+below($tablet)
			padding-x 13px
			padding-y 8px

		+below($tabletSmall)
			padding-y 6px

		+below($phone)
			padding-y 10px

	&__title
		margin-bottom 2px
		font-family $font-nunito-b
		font-size $font-default
		line-height 30px
		color $black

		+below($desktopLg)
			font-size $font-middle
			line-height 26px

		+below($desktop)
			font-size $font-little
			line-height 20px

		+below($tablet)
			font-size $font-little
			line-height 19px

		+below($phone)
			font-size $font-low
			line-height 22px

	&__description
		font-family $font-nunito
		font-size $font-middle
		line-height 22px
		color $gray

		+below($desktopLg)
			font-size $font-low
			line-height 18px

		+below($desktop)
			font-size $font-small
			line-height 16px

		+below($phone)
			font-size $font-little
			line-height 19px
