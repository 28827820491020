.license-slider
	padding 30px
	width 100%
	max-width 850px

	+below($desktopLg)
		max-width 760px

	+below($desktop)
		padding 20px
		max-width 620px

	+below($tablet)
		padding 15px
		max-width 530px

	+below($tabletSmall)
		padding 20px
		max-width 620px

	&__slider
		margin-bottom 30px
		width 100%

	&__slide
		height 600px

		+below($desktopLg)
			height 554px

		+below($desktop)
			height 470px

		+below($tablet)
			height 390px

		+below($tabletSmall)
			height 470px

		+below($phone)
			height 355px

	&__nav.slick-slider
		display flex
		align-items center

	&__nav .slick-slide
		margin-x 3px

	&__nav .slick-current &__slide-nav
		border 3px solid $bright-blue

	&__slide-nav
		border 3px solid transparent
		animate border .3s

	&__image
		size 100%
		object-fit contain

	&__arrow
		flex-shrink 0
		size 40px
		color $black
		background-color transparent
		border none
		transition color .3s

		&:hover
			color $deep-blue

		&_type_prev
			left 48px

			+below($desktopLg)
				left 38px

			+below($desktop)
				left 36px

			+below($tablet)
				left 26px

		&_type_next
			right 48px

			+below($desktopLg)
				right 38px

			+below($desktop)
				right 36px

			+below($tablet)
				right 26px

	&__arrow_type_prev &__icon
		transform rotate(180deg)

	&__icon
		width 100%
		height 100%
		color currentColor
