.partners-slider

	& .slick-slide.slick-active
		opacity 0
		transform translateX(50px)
		$animation = transform, opacity
		animate $animation .6s

	& .slick-slide.slick-active + .slick-slide.slick-active
		transition-delay .2s
	& .slick-slide.slick-active + .slick-slide.slick-active + .slick-slide.slick-active
		transition-delay .4s
	& .slick-slide.slick-active + .slick-slide.slick-active + .slick-slide.slick-active + .slick-slide.slick-active
		transition-delay .6s

	& .slick-slide
		margin-x 16px

		+below($desktopLg)
			margin-x 12px

		+below($desktop)
			margin-x 10px

		+below($tablet)
			margin-x 8px

	& .slick-list
		margin-x -16px

		+below($desktopLg)
			margin-x -12px

		+below($desktop)
			margin-x -10px

		+below($tablet)
			margin-x -8px

	&__card
		display flex
		justify-content center
		align-items center
		overflow hidden
		height 240px
		background-color $white
		border-radius 4px

		+below($desktopLg)
			height 180px

		+below($desktop)
			height 160px

		+below($tablet)
			height 128px

		+below($tabletSmall)
			height 96px

		+below($phone)
			height 128px

	&__image
		width 220px

		+below($desktopLg)
			width 166px

		+below($desktop)
			width 146px

		+below($tablet)
			width 120px

		+below($tabletSmall)
			width 90px

		+below($phone)
			width 120px

	&__arrow
		position absolute
		top -66px
		padding 0
		display flex
		justify-content center
		align-items center
		size 40px
		background-color $white
		border none
		border-radius 4px
		transition background-color .3s

		&:hover
			background-color $light-blue

		&_type_prev
			right 44px

			+below($desktopLg)
				right 35px

		&_type_next
			right 0

		+below($desktopLg)
			size 32px
			top -52px

		+below($desktop)
			top -45px

		+below($tablet)
			top -42px

	&__arrow_type_prev &__icon
		transform rotate(180deg)

	&__icon
		size 15px
		color $black

