.ui-kit
	position relative
	padding 15px 15px 200px
	background-color $light-gray

	&__item
		position relative
		margin-bottom 10px
		padding-top 25px
		border 1px solid #ccc

		&_background_dark
			color #fff
			background #444

		+below($phone)
			margin-bottom 0
			padding-top 0

	&__name
		margin-bottom 24px
		font-size $font-magor
		color $black

	&__content
		padding-y 10px
		display flex

		&_type_header
			margin 20px auto
			width 1120px

	&__col
		padding 10px
		min-width 50%

	&__check
		padding 7px 0

	&__medium
		max-width 300px
		+below($desktopLg)
			max-width 252px

		+below($desktop)
			max-width 224px

		+below($tablet)
			max-width 180px

		+below($tabletSmall)
			max-width 170px

		+below($phone)
			max-width 288px

	&__big
		max-width 700px
		+below($desktopLg)
			max-width 252px

		+below($desktop)
			max-width 224px

		+below($tablet)
			max-width 180px

		+below($tabletSmall)
			max-width 170px

		+below($phone)
			max-width 288px

	&__technique
		max-width 336px
		+below($desktop)
			max-width 224px

		+below($tablet)
			max-width 180px

		+below($tabletSmall)
			max-width 170px

		+below($phone)
			max-width 272px

	&__reviews
		max-width 632px
		+below($desktopLg)
			max-width 466px

		+below($desktop)
			max-width 426px

		+below($tablet)
			max-width 336px

		+below($tabletSmall)
			max-width 356px

		+below($phone)
			max-width 272px

	&__logo-card
		max-width 336px

		+below($desktopLg)
			max-width 252px

		+below($desktop)
			max-width 224px

		+below($tablet)
			max-width 180px

		+below($tabletSmall)
			max-width 170px

		+below($phone)
			max-width 272px