.privacy
	background-color $light-gray

	&__section
		padding-top 230px
		padding-bottom 130px

		+below($desktopLg)
			padding-top 200px
			padding-bottom 100px

		+below($desktop)
			padding-top 180px
			padding-bottom 80px

		+below($tablet)
			padding-top 168px
			padding-bottom 68px

		+below($tabletSmall)
			padding-top 145px
			padding-bottom 45px

		+below($phone)
			padding-top 136px
			padding-bottom 36px

	&__header
		position fixed
		z-index 100
		top 0
		left 0
		width 100%

	&__title
		margin-bottom 60px
		font-family $font-nunito-b
		font-size $font-magor
		line-height 55px
		text-transform uppercase
		color $black

		+below($desktopLg)
			font-size $font-great
			line-height 49px

		+below($desktop)
			margin-bottom 40px
			font-size $font-bigger
			line-height 44px

		+below($tablet)
			margin-bottom 32px
			font-size $font-default
			line-height 30px

		+below($tabletSmall)
			font-size $font-middle
			line-height 25px

		+below($phone)
			margin-bottom 22px
			font-size $font-low
			line-height 22px

	&__description
		font-family $font-nunito
		font-size $font-default
		line-height 30px
		color $black

		+below($desktopLg)
			font-size $font-average
			line-height 22px

		+below($desktop)
			font-size $font-middle
			line-height 20px

		+below($tablet)
			font-size $font-little
			line-height 19px

		a
			text-decoration none
			color $bright-blue
			animate color .3s

			&:hover
				color $blue

		li
			margin-left 20px
			list-style-type disc
			font-size $font-default
			line-height 30px
			color $black

			&:not(:last-child)
				margin-bottom 10px

			+below($desktopLg)
				font-size $font-average
				line-height 22px

			+below($desktop)
				font-size $font-middle
				line-height 20px

			+below($tablet)
				font-size $font-little
				line-height 19px
