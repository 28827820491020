.header
	position relative
	background-color $white

	+below($phone)
		&_active-burger &__icon-burger
			display none

		&_active-burger &__icon-close
			display block

		&_active-burger &__links
			transform translateX(0)

	&__container
		display flex
		align-items center
		justify-content space-between
		height 100px

		+below($desktop)
			height 66px

		+below($tablet)
			height 60px

		+below($tabletSmall)
			height 45px

		+below($phone)
			height 68px

	&__title
		margin-left 8px
		font-family $font-nunito-exb
		font-size $font-low
		line-height 20px
		color $dark-blue
		text-transform uppercase

		+below($desktop)
			font-size $font-tiny
			line-height 13px

		+below($tablet)
			margin-left 6px

		+below($tabletSmall)
			line-height 9px

		+below($phone)
			display none

	&__content
		display flex
		align-items center
		justify-content flex-end
		width 100%

	&__links
		display flex
		align-items center

		+below($phone)
			position absolute
			top 100%
			left 0
			padding 16px
			flex-direction column
			align-items flex-start
			width 100%
			background-color $white
			transform translateX(100%)
			animate transform .3s

	&__link
		font-family $font-nunito-sb
		font-size $font-middle
		line-height 25px
		white-space nowrap
		text-decoration none
		color $black
		border-bottom transparent
		transition color .3s

		&:not(:first-child)
			margin-left 30px

			+below($desktopLg)
				margin-left 24px

			+below($desktop)
				margin-left 16px

			+below($tablet)
				margin-left 13px

			+below($tabletSmall)
				margin-left 11px

			+below($phone)
				margin-left 0

		+below($desktopLg)
			font-size $font-low

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($phone)
			font-size $font-low
			line-height 22px

			&:not(:last-child)
				margin-bottom 16px

		&:hover,
		&.active
			color $bright-blue

	&__img
		size 64px

		+below($desktop)
			size 42px

		+below($tablet)
			size 34px

		+below($tabletSmall)
			size 26px

		+below($phone)
			size 36px

	&__logo
		display flex
		align-items center
		text-decoration none

	&__burger
		margin-left 14px
		display none
		align-items center
		justify-content center
		size 36px
		background-color $light-blue
		border transparent
		border-radius 4px

		+below($phone)
			display flex

	&__icon-burger
		display none
		size 18px

		+below($phone)
			display block

	&__icon-close
		display none
		size 24px

	&__button
		margin-left 40px
		flex-shrink 0
		width 100%
		max-width 206px
		white-space nowrap

		+below($desktopLg)
			margin-left 32px
			max-width 190px

		+below($desktop)
			margin-left 20px
			max-width 170px

		+below($tablet)
			margin-left 14px
			max-width 160px

		+below($tabletSmall)
			margin-left 20px
			max-width 126px

		+below($phone)
			margin-left 60px
			max-width 142px
			height 36px

	&__transition
		font-family $font-nunito-b
		font-size $font-low
		line-height 30px
		text-decoration none
		cursor pointer
		color $bright-blue
		background-color transparent
		border transparent
		animate color .3s

		&:hover
			color $blue

		+below($desktopLg)
			font-size $font-little
			line-height 25px

		+below($tablet)
			font-size $font-small
			line-height 18px

		+below($phone)
			font-size $font-low
			line-height 22px


