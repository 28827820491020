// Clear the float on any element.
clearfix()
	&:after
		display table
		clear both
		content ''

// Fix no working `-webkit-text-size-adjust: 100%;` in Android.
fix-text-size-adjust()
	background-image linear-gradient(transparent, transparent)

// Set element size
size($width, $height = $width)
	width $width
	height $height

// Set margin on left & right
margin-x($margin)
	margin-left $margin
	margin-right $margin

// Set margin on top & bottom
margin-y($margin)
	margin-top $margin
	margin-bottom $margin

// Set pading on left & right
padding-x($padding)
	padding-left $padding
	padding-right $padding

// Set padding on top & bottom
padding-y($padding)
	padding-top $padding
	padding-bottom $padding

// Transition optimize
animate($property, $time, $function = ease)
	backface-visibility hidden
	will-change $property
	transition-property $property
	transition-duration $time
	transition-timing-function $function

// Set one static retina image
retinaBackground($image, $ext = 'png') {
	background-image url('../images/' + $image + '.' + $ext)

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image url('../images/' + $image + '@2x.' + $ext)
	}
}
