.footer
	padding-top 48px
	padding-bottom 30px
	background-color $white

	+below($desktopLg)
		padding-top 44px
		padding-bottom 22px

	+below($desktop)
		padding-top 30px
		padding-bottom 20px

	+below($tablet)
		padding-top 20px
		padding-bottom 12px

	+below($tabletSmall)
		padding-top 24px
		padding-bottom 20px

	+below($phone)
		padding-y 16px

	&__cols
		margin-bottom 30px
		display flex
		justify-content space-between

		+below($desktopLg)
			margin-bottom 14px

		+below($desktop)
			margin-bottom 16px

		+below($tablet)
			margin-bottom 18px

		+below($tabletSmall)
			margin-bottom 0

		+below($phone)
			margin-bottom 10px
			display flex
			flex-direction column

	&__col
		+below($phone)
			margin-bottom 10px

		&_order-one
			+below($phone)
				order 3

		&_order-two
			margin-top 30px

			+below($desktop)
				margin-top 26px

			+below($tabletSmall)
				margin-top 23px

			+below($phone)
				margin-top 0
				order 4

		&_order-three
			+below($phone)
				order 5
				display flex
				flex-direction row

	&__nav
		display flex
		flex-direction column

		&:not(:last-child)
			margin-bottom 8px

			+below($desktopLg)
				margin-bottom 6px

			+below($phone)
				margin-bottom 0

		+below($phone)
			width 50%

	&__logo
		width 18%

		+below($desktop)
			width 14%

		+below($tablet)
			width 15%

		+below($phone)
			margin-bottom 16px
			order 1
			display flex
			justify-content center
			width 100%

	&__link-logo
		display flex
		justify-content center
		size 200px

		+below($desktopLg)
			size 150px

		+below($desktop)
			size 132px

		+below($tablet)
			size 106px

		+below($tabletSmall)
			size 80px

	&__row
		display flex
		justify-content flex-end

		+below($tabletSmall)
			align-items flex-end
			flex-direction column

		+below($phone)
			align-items center

	&__image
		size 160px

		+below($desktopLg)
			size 120px

		+below($desktop)
			size 106px

		+below($tablet)
			size 86px

		+below($tabletSmall)
			size 64px

		+below($phone)
			align-items center

	&__title
		margin-bottom 10px
		font-family $font-nunito
		font-size $font-low
		line-height 22px
		color $black

		+below($desktopLg)
			margin-bottom 10px
			font-size $font-little
			line-height 19px

		+below($desktop)
			margin-bottom 8px

		+below($tabletSmall)
			font-size $font-small
			line-height 15px

		+below($phone)
			margin-bottom 6px
			font-size $font-low
			line-height 22px

	&__phones
		display flex
		flex-direction column

	&__phone
		font-family $font-nunito
		font-size $font-average
		line-height 27px
		text-decoration none
		cursor pointer
		color $gray
		transition color .3s

		&:hover
			color $bright-blue

		&:first-child
			margin-bottom 16px
			+below($desktopLg)
				margin-bottom 10px

			+below($desktop)
				margin-bottom 6px

			+below($tabletSmall)
				margin-bottom 3px

			+below($phone)
				margin-bottom 4px

		+below($desktopLg)
			font-size $font-low
			line-height 22px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tablet)
			font-size $font-small
			line-height 16px

		+below($tabletSmall)
			font-size $font-tiny
			line-height 14px

		+below($phone)
			font-size $font-little
			line-height 19px

	&__address
		margin-bottom 16px
		display block
		font-family $font-nunito
		font-size $font-middle
		line-height 25px
		color $gray

		+below($desktopLg)
			margin-bottom 10px
			font-size $font-little
			line-height 19px

		+below($desktop)
			margin-bottom 6px
			font-size $font-small
			line-height 16px

		+below($tabletSmall)
			font-size $font-tiny
			line-height 14px

		+below($phone)
			margin-bottom 13px
			font-size $font-little
			line-height 19px

	&__mail
		font-family $font-nunito
		font-size $font-middle
		line-height 25px
		text-decoration none
		cursor pointer
		color $gray
		transition color .3s

		&:hover
			color $bright-blue

		+below($desktopLg)
			font-size $font-little
			line-height 19px

		+below($desktop)
			font-size $font-small
			line-height 16px

		+below($tabletSmall)
			font-size $font-tiny
			line-height 14px

		+below($phone)
			font-size $font-little
			line-height 19px

	&__link
		font-family $font-nunito
		font-size $font-low
		line-height 22px
		text-decoration none
		color $black
		transition color .3s
		&:not(:last-child)
			margin-bottom 8px

			+below($desktopLg)
				margin-bottom 6px

			+below($phone)
				margin-bottom 10px

		&:hover,
		&.active
			color $bright-blue

		+below($desktopLg)
			font-size $font-little
			line-height 19px

		+below($tabletSmall)
			font-size $font-tiny
			line-height 16px

		+below($phone)
			font-size $font-low
			line-height 22px

	&__company
		font-family $font-nunito
		font-size $font-low
		line-height 22px
		color $gray

		+below($desktopLg)
			font-size $font-little
			line-height 19px

		+below($desktop)
			font-size $font-small
			line-height 16px

		+below($tabletSmall)
			margin-bottom 6px
			font-size $font-tiny
			line-height 14px

		+below($phone)
			margin-bottom 8px
			font-size $font-little
			line-height 19px

	&__politics
		margin-left 22px
		padding 0
		font-family $font-nunito
		font-size $font-low
		line-height 22px
		text-decoration none
		cursor pointer
		color $gray
		background-color transparent
		border none
		transition color .3s

		&:hover
			color $bright-blue

		+below($desktopLg)
			margin-left 16px
			font-size $font-little
			line-height 19px

		+below($desktop)
			margin-left 20px
			font-size $font-small
			line-height 16px

		+below($tablet)
			margin-left 12px

		+below($tabletSmall)
			margin-left 0
			font-size $font-tiny
			line-height 14px

		+below($phone)
			font-size $font-little
			line-height 19px

	&__button
		max-width 205px
		white-space nowrap

		+below($desktopLg)
			max-width 190px

		+below($desktop)
			max-width 170px

		+below($tablet)
			max-width 158px

		+below($tabletSmall)
			max-width 126px

		+below($phone)
			margin-bottom 16px
			max-width 100%
			order 2

	&__social
		margin-top 16px

		+below($desktopLg)
			margin-top 10px

		+below($desktop)
			margin-top 6px

		+below($tabletSmall)
			margin-top 8px

		+below($phone)
			margin-top 10px
			margin-bottom 6px
			order 5

	&__transition
		font-family $font-nunito-b
		font-size $font-low
		line-height 30px
		text-decoration none
		cursor pointer
		color $bright-blue
		background-color transparent
		border transparent
		animate color .3s

		&:hover
			color $blue

		+below($desktopLg)
			font-size $font-little
			line-height 25px

		+below($tablet)
			font-size $font-small
			line-height 18px

		+below($phone)
			margin-top 26px
			margin-bottom 36px
			display flex
			justify-content center
			font-size $font-low
			line-height 22px
			order 2
