
.info-line
	background-color $dark-blue
	opacity 0
	transform translateY(100px)
	$animated = transform, opacity
	animate $animated .5s
	transition-delay .5s

	&__container
		padding-y 90px
		display flex
		justify-content space-between

		+below($desktopLg)
			padding-y 66px

		+below($desktop)
			padding-y 48px

		+below($tablet)
			padding-y 45px

		+below($tabletSmall)
			padding-y 30px

		+below($phone)
			padding-x 0
			padding-y 48px
			flex-direction column
			align-items center

	&__item
		display flex
		align-items center
		justify-content center
		width 100%
		max-width 254px

		+below($desktopLg)
			max-width 232px

		+below($desktop)
			max-width 182px

		+below($tablet)
			max-width 152px

		+below($phone)
			max-width 182px
			&:not(:last-child)
				margin-bottom 50px

	&__content
		margin-left 23px
		display flex
		flex-direction column
		width 130px

		+below($desktopLg)
			margin-left 20px
			width 106px

		+below($desktop)
			margin-left 18px
			width 94px

		+below($tablet)
			margin-left 12px
			width 74px

		+below($phone)
			margin-left 18px
			width 94px

	&__image
		width 98px
		height 100px

		+below($desktopLg)
			width 78px
			height 80px

		+below($desktop)
			width 68px
			height 70px

		+below($tablet)
			width 52px
			height 54px

		+below($tabletSmall)
			width 42px
			height 44px

		+below($phone)
			size 70px

	&__amount
		font-family $font-nunito-b
		font-size $font-large
		line-height 68px
		color $white

		+below($desktopLg)
			font-size $font-great
			line-height 49px

		+below($desktop)
			font-size $font-bigger
			line-height 44px

		+below($tablet)
			font-size $font-big
			line-height 38px

		+below($tabletSmall)
			font-size $font-medium
			line-height 32px

		+below($phone)
			font-size $font-bigger
			line-height 44px

	&__title
		font-size $font-medium
		line-height 28px
		color $white

		+below($desktopLg)
			font-size $font-average
			line-height 22px

		+below($desktop)
			font-size $font-middle
			line-height 20px

		+below($tablet)
			font-size $font-little
			line-height 16px

		+below($tabletSmall)
			line-height 14px

		+below($phone)
			font-size $font-middle
			line-height 20px
