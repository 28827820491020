.reviews
	padding 30px
	overflow hidden
	width 100%
	background-color $white
	border 2px solid transparent
	border-radius 8px
	transition border-color .3s

	&:hover
		border-color $light-blue

	+below($desktopLg)
		padding 20px

	+below($tablet)
		padding 13px

	+below($tabletSmall)
		padding 8px

	+below($phone)
		padding 10px

	&__content
		margin-top 16px
		display flex
		justify-content flex-end

		+below($desktopLg)
			margin-top 14px

		+below($tabletSmall)
			margin-top 6px

		+below($phone)
			margin-top 16px
			justify-content flex-start

	&__text
		margin-bottom 16px
		display -webkit-box
		font-size $font-middle
		line-height 25px
		-webkit-line-clamp 3
		-webkit-box-orient vertical
		overflow hidden
		color $gray

		+below($desktopLg)
			margin-bottom 14px
			font-size $font-low
			line-height 22px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tabletSmall)
			margin-bottom 6px

		+below($phone)
			margin-bottom 10px

	&__detail
		font-family $font-nunito-sb
		font-size $font-middle
		line-height 25px
		text-decoration none
		cursor pointer
		color $bright-blue
		background-color transparent
		border transparent
		animate color .3s

		&:hover
			color $blue

		+below($desktopLg)
			font-size $font-low
			line-height 22px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tablet)
			justify-content flex-start

	&__company
		font-size $font-middle
		line-height 25px
		color $black

		+below($desktopLg)
			font-size $font-low
			line-height 22px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tablet)
			justify-content flex-end
