.service-card
	size 100%
	overflow hidden
	font-size 0
	background-color $white
	border-radius 6px
	transition box-shadow .3s

	&:hover
		box-shadow 1px 24px 48px $card-shadow

	&__image
		width 100%
		height 400px
		object-fit cover
		object-position center

		+below($desktopLg)
			height 300px

		+below($desktop)
			height 268px

		+below($tablet)
			height 214px

		+below($tabletSmall)
			height 160px

		+below($phone)
			height 180px

	&__content
		padding 20px

		+below($desktopLg)
			padding 14px

		+below($tablet)
			padding 10px

		+below($tabletSmall)
			padding 8px

		+below($phone)
			padding 10px

	&__title
		margin-bottom 10px
		font-family $font-nunito-b
		font-size $font-default
		line-height 30px
		color $black

		+below($desktopLg)
			font-size $font-middle
			line-height 25px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tablet)
			margin-bottom 6px

		+below($phone)
			font-size $font-low
			line-height 22px
			text-overflow ellipsis

	&__description
		margin-bottom 10px
		display -webkit-box
		font-size $font-middle
		line-height 25px
		-webkit-line-clamp 3
		-webkit-box-orient vertical
		overflow hidden
		color $gray

		+below($desktopLg)
			font-size $font-low
			line-height 20px

		+below($desktop)
			font-size $font-small
			line-height 14px

		+below($tablet)
			margin-bottom 8px

		+below($phone)
			font-size $font-little
			line-height 19px

	&__detail
		display flex
		font-size $font-middle
		line-height 25px
		text-decoration none
		cursor pointer
		color $bright-blue
		background-color transparent
		border transparent

		+below($desktopLg)
			font-size $font-low
			line-height 22px

		+below($desktop)
			font-size $font-small
			line-height 16px

		+below($phone)
			font-size $font-little
			line-height 19px
