.technique-list
	&__technique
		$indent = -32px
		margin-top $indent
		margin-left $indent
		display flex
		flex-wrap wrap

		+below($desktopLg)
			$indent = -24px
			margin-top $indent
			margin-left $indent

		+below($desktop)
			$indent = -21px
			margin-top $indent
			margin-left $indent

		+below($tablet)
			$indent = -16px
			margin-top $indent
			margin-left $indent

		+below($phone)
			$indent = 0
			margin-top $indent
			margin-bottom -18px
			margin-left $indent
			flex-wrap nowrap
			overflow-x scroll

			&::-webkit-scrollbar
				width 0

	&__tech
		$indent = 32px
		margin-top $indent
		margin-left $indent
		width 'calc(25% - %s)' % $indent
		opacity 0
		transform translateX(50px)
		$animation = transform, opacity
		animate $animation .6s

		&_hide
			display none

		&:nth-child(2)
			transition-delay .2s
		&:nth-child(3)
			transition-delay .4s
		&:nth-child(4)
			transition-delay .6s

		&_active
			opacity 1
			transform translateX(0)

		+below($desktopLg)
			$indent = 24px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($desktop)
			$indent = 20px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($tablet)
			$indent = 16px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($phone)
			margin-top 0
			width 100%
			min-width 272px

			&:last-child
				margin-right 16px

	&__button
		margin-x auto
		margin-top 32px
		width 184px

		&_hide
			display none

		+below($desktopLg)
			margin-top 36px
			width 170px

		+below($desktop)
			margin-top 24px
			width 152px

		+below($tablet)
			margin-top 26px
			width 140px

		+below($tabletSmall)
			margin-top 14px
			width 112px

		+below($phone)
			display none
