.review-slider
	& .slick-slide.slick-active
		opacity 0
		transform translateX(50px)
		$animation = transform, opacity
		animate $animation .6s

	& .slick-slide.slick-active + .slick-slide.slick-active
		transition-delay .2s

	& .slick-slide
		+below($tablet)
			height 154px

		+below($tabletSmall)
			height 124px

		+below($phone)
			height 148px

	&.slick-slider
		display flex
		align-items center

	&__card
		margin-x 16px
		height 200px

		+below($desktopLg)
			margin-x 12px
			height 178px

		+below($desktop)
			margin-x 10px
			height 164px

		+below($tablet)
			margin-x 8px
			height 154px

		+below($tabletSmall)
			height 124px

		+below($phone)
			margin-x 0
			height 140px

	&__arrow
		size 40px
		flex-shrink 0
		color $black
		background-color transparent
		border none
		transition color .3s

		&:hover
			color $deep-blue

		+below($desktop)
			size 24px

		+below($tablet)
			size 22px

	&__arrow_type_prev &__icon
		transform rotate(180deg)

	&__icon
		size 100%

	&__dots
		position absolute
		left 50%
		bottom -36px
		display flex
		align-items center
		justify-content center
		transform translateX(-50%)

		+below($desktopLg)
			bottom -33px

		+below($desktop)
			bottom -30px

		+below($tablet)
			bottom -26px

		+below($tabletSmall)
			bottom -25px

		li
			size 12px
			background-color $light-blue
			border-radius 50%
			transition background-color .3s

			&:not(:first-child)
				margin-left 13px

				+below($desktop)
					margin-left 8px

				+below($tablet)
					margin-left 6px

			+below($desktop)
				size 8px

			+below($tabletSmall)
				size 6px

			+below($phone)
				size 10px

			button
				width 100%
				height 100%
				font-size 0
				background-color transparent
				border none

		li.slick-active
			background-color $blue
