.index
	position relative
	margin 10px auto
	padding 20px 10px
	width 500px

	&__h2
		margin 20px 0 10px
		font-size $font-average
		text-align center


	&__item
		position relative
		margin-bottom 5px
		padding 5px 10px
		padding-right 30px
		display block
		font-size $font-little
		text-decoration none
		color #000
		background-color rgba(0, 0, 0, .1)
		transition .3s ease-out

		&after
			position absolute
			top 50%
			right 10px
			display block
			size 10px
			content ''
			border-radius 50%
			transform translateY(-50%)


		&hover
			background-color rgba(0, 0, 0, .2)


		&_state_in-progress:after
			background-color #FBBC05


		&_state_done:after
			background-color #34A853



