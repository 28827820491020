.accordion
	margin-x auto
	overflow hidden
	max-width 872px
	background-color $white
	border-radius 8px

	+below($desktopLg)
		max-width 654px
		border-radius 6px

	+below($desktop)
		max-width 582px

	+below($tabletSmall)
		max-width 466px

	+below($phone)
		max-width 288px

	&__item
		position relative
		animate background-color .3s

		&::before
			position absolute
			top 0
			left 50%
			width calc(100% - 30px * 2)
			height 1px
			content ''
			background-color transparent
			animate background-color .3s
			transform translateX(-50%)

			+below($desktopLg)
				width calc(100% - 22px * 2)

			+below($phone)
				width calc(100% - 10px * 2)

		&:hover
			background-color $light-blue


	&__item:not(:first-child)::before
		background-color $lighter-blue

	&__item:hover::before
	&__item:hover + &__item::before
		background-color transparent

	&__item:hover &
		&__title,
		&__icon-plus
			color $blue

	&__item_state_open:hover
		background-color transparent

	&__item_state_open:hover &
		&__title,
		&__icon-plus
			color $black

	&__item_state_open &
		&__content
			height auto
			opacity 1

		&__icon-minus
			display block

		&__icon-plus
			display none

	&__button
		padding-x 30px
		padding-y 16px
		display flex
		justify-content space-between
		align-items center
		width 100%
		text-align left
		background-color transparent
		border none

		+below($desktopLg)
			padding-x 22px
			padding-y 12px

		+below($desktop)
			padding-x 20px
			padding-y 13px

		+below($phone)
			padding-x 10px
			padding-y 6px

	&__title
		font-family $font-nunito-b
		font-size $font-default
		line-height 30px
		color $black
		animate color .3s

		+below($desktopLg)
			font-size $font-middle
			line-height 25px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($phone)
			max-width 220px

	&__content
		overflow hidden
		height 0
		opacity 0
		$rules = height, opacity
		animate $rules .2s ease-out

	&__icon-plus,
	&__icon-minus
		size 40px
		color $black

		+below($desktopLg)
			size 30px

		+below($desktop)
			size 24px

		+below($tabletSmall)
			size 18px

		+below($phone)
			size 24px

	&__icon-minus
		display none

	&__icon-plus
		display block
		animate color .3s

	&__description
		padding-x 30px
		padding-bottom 16px
		min-height 46px
		font-size $font-middle
		line-height 25px
		color $gray
		animate color .3s

		+below($desktopLg)
			padding-x 22px
			padding-bottom 12px
			min-height 42px
			font-size $font-low
			line-height 22px

		+below($desktop)
			padding-x 20px
			padding-bottom 7px
			min-height 30px
			font-size $font-small
			line-height 16px

		+below($phone)
			padding-x 10px
			padding-bottom 6px
			min-height 22px

		a
			text-decoration none
			color $bright-blue

			&:hover
				color $blue
