.service-card-list
	$indent = -32px
	margin-top $indent
	margin-left $indent
	overflow hidden

	+below($desktopLg)
		$indent = -26px
		margin-top $indent
		margin-left $indent

	+below($desktop)
		$indent = -22px
		margin-top $indent
		margin-left $indent

	+below($tablet)
		$indent = -15px
		margin-top $indent
		margin-left $indent

	+below($tabletSmall)
		$indent = -16px
		margin-top $indent
		margin-left $indent

	+below($phone)
		margin-left 0

	&__service-card
		display flex
		flex-wrap wrap
		width 100%

	&__tech
		$indent = 32px
		margin-top $indent
		margin-left $indent
		width 'calc(25% - %s)' % $indent
		opacity 0
		transform translateX(50px)
		$animation = transform, opacity
		animate $animation .6s

		&:nth-child(2)
			transition-delay .2s
		&:nth-child(3)
			transition-delay .4s
		&:nth-child(4)
			transition-delay .6s
		&:nth-child(5)
			transition-delay .4s
		&:nth-child(6)
			transition-delay .6s

		&_active
			opacity 1
			transform translateX(0)

		+below($desktopLg)
			$indent = 24px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($desktop)
			$indent = 22px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($tablet)
			$indent = 15px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($tabletSmall)
			$indent = 16px
			margin-top $indent
			margin-left $indent
			width 'calc(25% - %s)' % $indent

		+below($phone)
			margin-left 0
			width 100%

		&_size_middle
			width 'calc(50% - %s)' % $indent

			+below($desktopLg)
				$indent = 24px
				width 'calc(50% - %s)' % $indent

			+below($desktop)
				$indent = 22px
				width 'calc(50% - %s)' % $indent

			+below($tablet)
				$indent = 15px
				width 'calc(50% - %s)' % $indent

			+below($tabletSmall)
				$indent = 16px
				width 'calc(50% - %s)' % $indent

			+below($phone)
				width 100%
