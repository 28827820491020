.button
	padding 16px 32px
	display flex
	justify-content center
	align-items center
	width 100%
	height 56px
	white-space nowrap
	cursor pointer

	text-decoration none
	color $white
	background-color $bright-blue
	border 1px solid transparent

	border-radius 4px
	transition box-shadow .3s, background-color .3s
	will-change box-shadow, background-color

	&:disabled
		background-color $gray

	&:disabled:hover
		box-shadow none

	&:focus
		background-color $blue

	&:hover
		box-shadow 0 4px 6px $button-shadow

	+below($desktopLg)
		padding 12px 30px
		height 54px

	+below($desktop)
		padding 12px 24px
		height 42px

	+below($tablet)
		padding 12px 22px
		height 36px

	+below($phone)
		padding 10px 16px
		height 48px

	&__text
		font-family $font-nunito
		font-size $font-middle
		line-height 25px

		+below($desktopLg)
			font-size $font-low
			line-height 20px

		+below($desktop)
			font-size $font-little
			line-height 19px

		+below($tablet)
			font-size $font-small
			line-height 16px

		+below($phone)
			font-size $font-little
			line-height 19px
