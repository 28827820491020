.social
	display flex

	&__img
		size 40px

		+below($desktopLg)
			size 30px

		+below($desktop)
			size 26px

		+below($tablet)
			size 22px

		+below($tabletSmall)
			size 32px

		+below($phone)
			size 36px

	&__icon
		display block
		text-decoration none
		cursor pointer
		background-color transparent
		border none

		&:not(:first-child)
			margin-left 20px

			+below($desktopLg)
				margin-left 9px

			+below($desktop)
				margin-left 8px

			+below($tablet)
				margin-left 6px
