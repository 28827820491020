.main
	background-color $light-gray

	&__section
		margin-bottom 130px
		font-size 0

		&_active
			.about-company,
			.info-line,
			.faq,
			.any-questions
				opacity 1
				transform translateY(0)

			.partners-slider .slick-slide.slick-active,
			.review-slider .slick-slide.slick-active
				opacity 1
				transform translateX(0)

		+below($desktopLg)
			margin-bottom 100px

		+below($desktop)
			margin-bottom 80px

		+below($tablet)
			margin-bottom 60px

		+below($tabletSmall)
			margin-bottom 45px

		+below($phone)
			margin-bottom 36px

		&_first
			margin-top 100px

			+below($desktop)
				margin-top 66px

			+below($tablet)
				margin-top 60px

			+below($tabletSmall)
				margin-top 46px

			+below($phone)
				margin-top 68px


		&_review
			margin-bottom 166px

			+below($desktopLg)
				margin-bottom 134px

			+below($desktop)
				margin-bottom 112px

			+below($tablet)
				margin-bottom 88px

			+below($tabletSmall)
				margin-bottom 72px

	&__header
		position fixed
		z-index 100
		top 0
		left 0
		width 100%

	&__about-company
		margin-bottom 60px

		+below($desktopLg)
			margin-bottom 34px

		+below($desktop)
			margin-bottom 32px

		+below($tablet)
			margin-bottom 20px

		+below($phone)
			margin-bottom 36px

	&__technique-list
		+below($phone)
			padding-right 0
			padding-left 0

			.info-block
				padding-x 16px
