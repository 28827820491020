.not-found
	background-color $light-gray

	&__content
		display flex
		flex-direction column
		align-items center
		justify-content center
		height 100vh

	&__image
		margin-bottom 80px
		width 812px
		height 450px

		+below($desktopLg)
			margin-bottom 76px
			width 734px
			height 406px

		+below($desktop)
			margin-bottom 78px
			width 700px
			height 386px

		+below($tablet)
			margin-bottom 68px
			width 540px
			height 300px

		+below($tabletSmall)
			margin-bottom 66px

		+below($phone)
			margin-bottom 52px
			width 256px
			height 142px

	&__title
		margin-bottom 26px
		font-family $font-nunito-b
		font-size 28px
		line-height 34px
		color $black

		+below($desktopLg)
			font-size 22px
			line-height 25px

		+below($desktop)
			margin-bottom 16px
			font-size 18px
			line-height 22px

		+below($tablet)
			font-size 16px
			line-height 18px

		+below($tabletSmall)
			margin-bottom 12px
			line-height 22px

	&__description
		font-family $font-nunito-b
		font-size 28px
		line-height 34px
		color $black

		+below($desktopLg)
			font-size 22px
			line-height 25px

		+below($desktop)
			font-size 18px
			line-height 22px

		+below($tablet)
			font-size 16px
			line-height 18px

		+below($tabletSmall)
			line-height 22px

		a
			text-decoration none
			color $bright-blue
			animate color .3s

			&:hover
				color $blue
