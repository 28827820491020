	@font-face
		font-family 'Nunito Sans'
		src url('../fonts/woff2/nunitosans-regular.woff2') format('woff2'),
			url('../fonts/woff/nunitosans-regular.woff') format('woff'),
			url('../fonts/ttf/nunitosans-regular.ttf') format('truetype')
		font-weight 400
		font-style normal

	@font-face
		font-family 'Nunito SansSB'
		src url('../fonts/woff2/nunitosans-semibold.woff2') format('woff2'),
			url('../fonts/woff/nunitosans-semibold.woff') format('woff'),
			url('../fonts/ttf/nunitosans-semibold.ttf') format('truetype')
		font-weight 600
		font-style normal

	@font-face
		font-family 'Nunito SansB'
		src url('../fonts/woff2/nunitosans-bold.woff2') format('woff2'),
			url('../fonts/woff/nunitosans-bold.woff') format('woff'),
			url('../fonts/ttf/nunitosans-bold.ttf') format('truetype')
		font-weight 700
		font-style normal

	@font-face
		font-family 'Nunito SansExB'
		src url('../fonts/woff2/nunitosans-extrabold.woff2') format('woff2'),
			url('../fonts/woff/nunitosans-extrabold.woff') format('woff'),
			url('../fonts/ttf/nunitosans-extrabold.ttf') format('truetype')
		font-weight 800
		font-style normal
