.popup-more
	padding 30px
	max-width 710px

	+below($desktop)
		padding 25px

	+below($phone)
		padding 16px 40px 16px 16px
		width 100%

	&__title
		margin-bottom 30px
		font-family $font-nunito-b
		font-size $font-big
		line-height 38px
		text-align center
		color $black

		+below($desktopLg)
			font-size $font-default
			line-height 30px

		+below($tablet)
			margin-bottom 20px
			font-size $font-middle
			line-height 25px

		+below($tabletSmall)
			font-size $font-default
			line-height 30px

		+below($phone)
			margin-bottom 24px
			font-size $font-default
			line-height 30px
			text-align start

	&__description
		font-size $font-low
		line-height 22px
		color $black

		+below($desktopLg)
			font-size $font-little
			line-height 19px
