.about-company
	display flex
	width 100%
	max-height 430px
	background-color $white
	opacity 0
	transform translateY(100px)
	$animated = transform, opacity
	animate $animated .5s

	+below($desktopLg)
		max-height 404px

	+below($desktop)
		max-height 331px

	+below($tablet)
		max-height 100%

	+below($phone)
		flex-direction column

	&__image
		width 100%
		max-width 630px
		object-fit cover
		object-position center

		+below($desktopLg)
			max-width 474px

		+below($desktop)
			max-width 420px

		+below($tablet)
			max-width 338px

		+below($tabletSmall)
			max-width 262px

		+below($phone)
			max-width 100%
			height 200px

	&__content
		padding-y 30px
		padding-right 90px
		padding-left 30px
		display flex
		flex-direction column
		width 100%

		+below($desktopLg)
			padding-y 32px
			padding-right 30px
			padding-left 22px

		+below($desktop)
			padding-x 20px
			padding-y 26px

		+below($tablet)
			padding 14px

		+below($tabletSmall)
			padding-x 12px
			padding-y 14px

		+below($phone)
			padding 12px

	&__title
		margin-bottom 20px
		font-family $font-nunito-b
		font-size $font-big
		line-height 34px
		color $black

		+below($desktopLg)
			font-size $font-default
			line-height 25px

		+below($desktop)
			margin-bottom 20px
			font-size $font-middle
			line-height 22px

		+below($tablet)
			margin-bottom 10px
			font-size $font-low
			line-height 19px

		+below($tabletSmall)
			margin-bottom 12px
			font-size $font-low
			line-height 22px

		+below($phone)
			margin-bottom 10px
			line-height 22px

	&__description
		margin-bottom 32px
		font-family $font-nunito
		font-size $font-middle
		line-height 25px
		color $gray

		+below($desktopLg)
			margin-bottom 22px
			font-size $font-low
			line-height 22px

		+below($desktop)
			margin-bottom 20px
			font-size $font-little
			line-height 19px

		+below($tablet)
			margin-bottom 14px

		+below($tabletSmall)
			margin-bottom 12px

		+below($phone)
			margin-bottom 16px

	&__text
		display -webkit-box
		overflow hidden
		-webkit-box-orient vertical
		-webkit-line-clamp 7

	&__link
		text-decoration none
		cursor pointer
		color $bright-blue
		transition color .3s

		&:hover
			color $blue

	&__button
		max-width 200px
		white-space nowrap

		+below($desktopLg)
			max-width 184px

		+below($desktop)
			max-width 170px

		+below($tablet)
			max-width 152px

		+below($tabletSmall)
			max-width 122px

		+below($phone)
			max-width 100%
