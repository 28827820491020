.main-slider
	position relative
	width 100%

	&__slide
		position relative
		width 100%
		height 100%

	&__image
		width 100%
		height 800px
		background-repeat no-repeat
		background-position center
		background-size cover

		+below($desktopLg)
			height 600px

		+below($desktop)
			height 540px

		+below($tablet)
			height 420px

		+below($tabletSmall)
			height 360px

		+below($phone)
			height 380px

	&__arrow
		position absolute
		z-index 1
		top 50%
		size 40px
		color $white
		background-color transparent
		border none
		transform translateY(-50%)
		transition color .3s

		+below($desktopLg)
			size 30px

		+below($desktopLg)
			size 25px

		&:hover
			color $deep-blue

		&_type_prev
			left 40px

			+below($desktopLg)
				left 30px


			+below($tablet)
				left 22px

		&_type_next
			right 40px

			+below($desktopLg)
				right 30px

			+below($tablet)
				right 18px

	&__arrow_type_prev &__icon
		transform rotate(180deg)

	&__icon
		width 100%
		height 100%
		color currentColor

	&__wrap-text
		position absolute
		top 50%
		left 50%
		display flex
		flex-direction column
		align-items center
		width 100%
		max-width 638px
		text-align center
		transform translate(-50%, -50%)

		+below($desktop)
			max-width 510px

		+below($tablet)
			max-width 386px

		+below($tabletSmall)
			max-width 316px

		+below($phone)
			max-width 262px

	&__title
		margin-bottom 20px
		font-size $font-larger
		line-height 70px
		color $white

		+below($desktop)
			margin-bottom 18px
			font-size $font-meduim-large
			line-height 55px

		+below($tablet)
			font-size $font-magor
			line-height 40px

		+below($tabletSmall)
			margin-bottom 14px
			font-size $font-bigger
			line-height 32px

		+below($phone)
			margin-bottom 16px
			font-size $font-secondary
			line-height 26px

	&__description
		margin-bottom 20px
		font-size $font-average
		line-height 27px
		color $white

		+below($desktopLg)
			margin-bottom 16px

		+below($desktop)
			margin-bottom 12px
			font-size $font-low
			line-height 22px

		+below($tablet)
			font-size $font-little

		+below($phone)
			margin-bottom 24px

	&__button
		width auto

		+below($phone)
			width 256px

	&__dots
		position absolute
		left 50%
		bottom 40px
		display flex
		align-items center
		justify-content center
		transform translateX(-50%)

		+below($desktopLg)
			bottom 30px

		+below($tablet)
			bottom 40px

		+below($tabletSmall)
			bottom 20px

		+below($phone)
			bottom 24px

		li
			width 100px
			height 2px
			background-color $gray

			+below($desktopLg)
				width 80px

			+below($tablet)
				width 60px

			+below($tabletSmall)
				width 40px

			+below($phone)
				width 80px
				height 3px

			&:not(:first-child)
				margin-left 4px

				+below($phone)
					margin-left 8px

			button
				width 100%
				height 100%
				font-size 0
				background-color transparent
				border none

		li.slick-active
			background-color $white
