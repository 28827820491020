.info-block
	margin-bottom 48px
	display flex
	align-items center
	flex-direction column

	+below($desktopLg)
		margin-bottom 40px

	+below($desktop)
		margin-bottom 32px

	+below($tablet)
		margin-bottom 26px

	+below($tabletSmall)
		margin-bottom 15px

	+below($phone)
		margin-bottom 16px

	&__title
		font-family $font-nunito-b
		font-size $font-magor
		line-height 55px
		text-align center
		letter-spacing .01em
		color $black

		+below($desktop)
			font-size $font-secondary
			line-height 35px

		+below($tablet)
			font-size $font-default
			line-height 28px

		+below($tabletSmall)
			font-size $font-middle
			line-height 25px

		+below($phone)
			font-size $font-default
			line-height 30px

	&__description
		margin-top 5px
		font-size $font-middle
		line-height 25px
		text-align center
		color $gray

		+below($desktopLg)
			margin-top 2px
			font-size $font-low
			line-height 22px

		+below($desktop)
			margin-top 4px
			font-size $font-little
			line-height 19px
